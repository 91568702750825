.category-slider{
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.07); 
  background: $white;
  padding:20px 0;
  .item {
    text-align: center;
    a {
      display:inline-block;
      padding:20px 40px;
      background:$light;
      h4 {
        font-size:14px;
        margin-top:10px;
      }
      i {
        font-size:40px;
      }
    } 
  }
}
