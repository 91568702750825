.client-slider-03{
	padding: 30px 0 0 0;
}

.stores{
	padding-bottom: 70px;
	.block{
		margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	padding-top: 70px;
	.store-letter{
		font-size: 20px;
		margin-bottom: 16px;
	}
	hr{
		margin-bottom: 25px;
	}
	ul.store-list{
		li{
			a{
				display: block;
				font-size: 15px;
				color: $text-color;
				margin-bottom: 25px;
				&:hover{
					text-decoration: underline;
					color: $primary-color;
				}
			}
		}
	}
}