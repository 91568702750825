// Store Single Styles
.store-coupon{
	padding: 30px 0;
	.block{
		padding: 25px 30px 30px;
		background: $light;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
		.media{
			@include tablet {
				-webkit-flex-wrap: wrap;
				    -ms-flex-wrap: wrap;
				        flex-wrap: wrap;
			}
			.image-content{
				@include tablet {
					-webkit-flex-grow: 1;
					        flex-grow: 1;
					        width: 100%;
					        justify-content: center;
    						padding-bottom: 30px;
				}
			}
			.media-body{
				margin: 0 75px;
				h4{
					font-size: 30px;
					font-weight: bold;
					letter-spacing: 0.05em;
				}
				ul{
					li{
						font-weight: bold;
						font-size: 17px;
						padding:0 15px;
						border-right: 1px solid $border-color;
						&:last-child{
							border: none;
						}
						a{
							color: $primary-color;
						}
					}
				}
				p{
					font-size: 14px;
					line-height: 26px;
					color: $dark-gray;
					letter-spacing: 0;
					margin: 20px 0;
				}
				@include tablet {
					-webkit-flex-grow: 1;
					        flex-grow: 1;
					        width: 100%;
					        text-align: center;
				}
				@include mobile {
					margin: 0;
				}
			}	
		} 
		.social-content{
			@include tablet {
				-webkit-flex-grow: 1;
				        flex-grow: 1;
				        width: 100%;
				        padding-top: 30px;
				        text-align: center;
			}
			ul.social-circle-icons{
				li{
					margin-right: 7px;
					&:last-child{
						margin-right: 0;
					}
					a{
						display: block;
						font-size: 15px;
						line-height: 38px;
						color: $primary-color;
						height: 38px;
						width: 38px;
						border: 2px solid $primary-color;
						background: $light;
						-webkit-border-radius: 100%;
						        border-radius: 100%;
						&:hover{
							text-decoration: none;
							color: $light;
							background: $primary-color;
						}
					}
				}
			}
			.share{
				font-size: 15px;
				line-height: 28px;
				color: $dark-gray;
				@include tablet {
					text-align: center !important;
					margin-top: 10px;
				}
			} 	
		}
	}
}

.store{
	padding: 0 0 80px 0;
	.block{
		.store-nav{
			.nav-item{
				.nav-link{
					font-size: 17px;
					padding: 0 40px 0 0;
					margin-bottom: 15px;
				}
			}
		}
	}
}