
article{
	background: $light;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1); 
	padding: 30px;
	margin-bottom: 30px;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	.image, .video, .post-slider{
		margin-bottom: 25px;
	}
	img{
		width: 100%;
		-webkit-border-radius: 3px;
		        border-radius: 3px;
	}
	.video{
		position:relative;
		&:before{
			-webkit-border-radius: 3px;
			        border-radius: 3px;
		}
		.video-button{
			position: absolute;
		    left: 0;
		    top: 0;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    width: 100%;
		    height: 100%;
		}
		.video-box{
			a{
			    width: 100%;
				height: 100%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				img{
					width: 79px;
				}
				iframe{
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.post-slider{
		.owl-nav{
		    position: absolute;
		    right: 5px;
		    bottom: 5px;
		    i{
		    	font-size: 30px;
		    	color: $light;
		    }
		}
		.owl-nav [class*=owl-]{
			padding: 7px 16px;
			background-color: rgba(255,255,255,.35);
			&:hover{
				background-color: $primary-color;
			}
		}
	}
	h3{
		margin-bottom: 5px;
	}
	ul{
		margin-bottom: 15px;
		li{
			margin-right: 10px !important;
			color: $dark-gray;
			a{
				color: $primary-color;
			}
		}
	}
	p{
		line-height: 26px;
		color: $dark-gray;
		margin-bottom: 24px;
	}
	.btn{
		margin-bottom: 10px;
	}
}
.single-blog{
	padding-top: 50px !important;
}
article.single-post{
	img{
		margin-bottom: 35px;
	}
	p{
		margin-bottom: 35px;
	}
}
.comment{
	background: $light;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1); 
	padding: 30px;
	margin-bottom: 30px;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	h4{
		font-size: 20px;
		letter-spacing: 0;
	}
	button{
		margin: 10px 0;
	}
}